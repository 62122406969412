import { Delete, Edit } from "@mui/icons-material"
import { Box, Chip, Grid, IconButton, Paper, Typography } from "@mui/material"
import { Product } from "../models/product.model"
import { ProductView } from "./product-view.component"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export const ProductsAsGrid = (props) => {
    const [selectedProductIdForView, setSelectedProductIDForView] = useState<any>(undefined)
    const [t] = useTranslation();

    return <Grid container >
        {props.filteredProducts.map((product: Product, index) => (
            <Grid item xs={12} sm={6} md={3} key={index} padding={1} marginBottom={2}>
                <Box sx={{ padding: '10px', position: 'relative', backgroundColor: "primary.light", borderRadius: 4, height: "100%"}}>
                    <IconButton aria-label="edit" onClick={() => props.handleEditProduct(product)} style={{ position: 'absolute', top: '5px', right: '5px', color: "black" }}>
                        <Edit />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => props.onDelete(product)} style={{ position: 'absolute', top: '5px', left: '5px', color: "black" }}>
                        <Delete />
                    </IconButton>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <img src={product.thumbnailPath} alt={product.name} style={{ maxWidth: '100%', maxHeight: '150px', borderRadius: 16, cursor: "pointer" }} 
                                onClick={() => {setSelectedProductIDForView(product.id)}}
                            />
                            {product.siblings && product.siblings.length > 0 && <Box key={index} sx={{backgroundColor: "primary.dark"}}>
                                    <Box display={"flex"} marginBlock={1} marginInline={1} >
                                        {product.siblings.map(sibling => (<Box marginRight={1} key={sibling.id} sx={{cursor: "pointer"}} onClick={() => {{
                                            setSelectedProductIDForView(sibling.id)
                                        }}}>
                                            <img src={sibling.thumbnailPath} width={"25px"} height={"25px"} style={{borderRadius: 6}}></img>
                                        </Box>))}
                                    </Box>
                            </Box>}
                        </Box>
                        <Typography variant="caption">{product.brand}</Typography>
                        <Typography variant="subtitle1"><b>{product.name}</b></Typography>
                        <Typography variant="caption">#{product.sku}</Typography>
                        <Typography variant="caption">{product.width} cm x {product.length} cm</Typography>
                        <Box display={"flex"}>
                            {product.application_types.map((type, index) => <Typography key={index} variant="caption"><Chip size='small' label={t('PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.'+type.toUpperCase())} /></Typography>)}
                        </Box>
                    </div>
                </Box>
        </Grid>
        ))}
       {selectedProductIdForView && <ProductView selectedProductForViewID={selectedProductIdForView} setSelectedProductForView={setSelectedProductIDForView} />}
    </Grid>
}