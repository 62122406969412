import React, { useEffect, useRef, useState } from 'react';
import {
    Typography,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Container,
    Grid,
    FormControlLabel,
    RadioGroup,
    Radio,
    Box,
    Divider,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    LinearProgress,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../../common/components/PageContainer';
import Papa from 'papaparse';
import { EditProduct } from '../../../modules/vendor/edit-product/models/edit-product.model';
import { Product, Size } from '../../../modules/vendor/my-products/models/product.model';
import { VendorState } from '../../../redux/vendor/rootReducer';
import { useSelector } from 'react-redux';
import { ProductVariant } from '../../../modules/vendor/edit-product/models/variant.model';
import UploadIcon from "../../../assets/icons/upload-icon.png"
import { base64ToBlob } from '../../../common/utils/utils';
import { v4 } from 'uuid';
import { useProductsApi } from '../../../common/hooks/use-products-api.hook';
import { config, useSettingsApi } from '../../../common/hooks/use-settings-api.hook';

export const Migrations = () => {
    const { t, i18n } = useTranslation();
    const [products, setProducts] = useState([]);
    const [editProducts, setEditProducts] = useState<EditProduct[]>([]);
    const [images, setImages] = useState([]); // To store the list of images
    const vendorTheme = useSelector((state: VendorState) => state.settings.theme);
    const productsAPI = useProductsApi()
    const [errorProducts, setErrorProducts] = useState<EditProduct[]>([])
    const [processingStarted, setProcessingStarted] = useState(false)
    const csvFileInput = useRef(null)
    const imageFilesInput = useRef(null)
    const theme = useTheme();
    const settingsAPI = useSettingsApi()

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        const mapProductType = (category) => {
            switch (category) {
                case 'Sol stratifié':
                case 'Parquet contrecollé':
                case 'Vinyl effet bois':
                    return 'hardwood';
                case 'Carrelage intérieur':
                case 'Carrelage intérieur/extérieur':
                case 'Carrelage extérieur':
                case 'Carrelage Salle de bain':
                    return 'tile';
                default:
                    return null;
            }
        };

        const mapApplicationType = (applicationType) => {
            switch (applicationType) {
                case 'wall':
                    return 'walls';
                case 'floor':
                    return 'floor';
                default:
                    return null;
            }
        };

        const mapPatternType = (pattern) => {
            switch (pattern) {
                case 'alternating thirds':
                    return 'stackbond';
                case 'grid':
                    return 'bricks';
                case 'grid-2x2':
                    return 'stackbond-2x2';
                case 'hexagon':
                    return 'hexagonal';
                default:
                    return null;
            }
        };

        if (file) {
            Papa.parse(file, {
                header: true,
                dynamicTyping: true,
                complete: (results) => {
                    const mappedProducts = results.data.map((item) => {
                            const product = new Product();
                            const width = item.tile_width?.split(' ')[0]
                            const height = item.min_tile_length?.split(' ')[0]
                            return product
                                .setName(item.name)
                                .setDescription(item.name)
                                .setBrand(config.company)
                                .setApplication_types((item.Surface_applicability ? item.Surface_applicability.split(',') : []).map(e => e.replace(' ', '').toLowerCase()).map(e => mapApplicationType(e)))
                                .setType(mapProductType(item.category))
                                .setProductTypeName(mapProductType(item.category))
                                .setPattern(mapPatternType(item.Tile_layout.split(",")[0].replace(' ', '')))
                                .setColor("default")
                                .setId(v4())
                                .setProductTypeId(item.productTypeId)
                                .setVendor_code("TO BE REPLACED")
                                .setSku(item.SKU_number+"")
                                .setDescription(item.name)
                                .setSizeName(`${item.tile_width} x ${item.min_tile_length}`)
                                .setIsVisible(true)
                                .setThumbnailPath("TO BE REPLACED")
                                .setWidth(Number.parseFloat(item.tile_width?.split(' ')[0]))
                                .setLength(Number.parseFloat(item.min_tile_length?.split(' ')[0]))
                                .setUnit("cm")
                                .setLink(item.Link)
                                .setSpecifics({
                                    collections: item.collection ? item.collection?.split(',') : [],
                                    colors: ["TO BE REPLACED"]
                                })
                                .setVisualization(null)
                                .setSize({ width: width, height: height, unit: "cm" } as Size)
                                .setIsDefault(true)
                                .setProductColors([])
                                .setSiblings(item.Tile_layout.split(",").filter(pattern => mapPatternType(pattern.replace(' ', '')) !== product.pattern).map(pattern => {
                                    let sibling: Product = new Product()
                                    return sibling  .setName(item.name)
                                                    .setDescription(item.name)
                                                    .setId(v4())
                                                    .setBrand(config.company)
                                                    .setApplication_types((item.Surface_applicability ? item.Surface_applicability.split(',') : []).map(e => e.replace(' ', '').toLowerCase()).map(e => mapApplicationType(e)))
                                                    .setType(mapProductType(item.category))
                                                    .setProductTypeName(mapProductType(item.category))
                                                    .setPattern(mapPatternType(pattern.replace(' ', '')))
                                                    .setColor("default")
                                                    .setProductTypeId(item.productTypeId)
                                                    .setVendor_code("TO BE REPLACED")
                                                    .setSku(item.SKU_number+"")
                                                    .setDescription(item.name)
                                                    .setSizeName(`${item.tile_width} x ${item.min_tile_length}`)
                                                    .setIsVisible(true)
                                                    .setThumbnailPath("TO BE REPLACED")
                                                    .setWidth(Number.parseFloat(item.tile_width?.split(' ')[0]))
                                                    .setLength(Number.parseFloat(item.min_tile_length?.split(' ')[0]))
                                                    .setUnit("cm")
                                                    .setLink(item.Link)
                                                    .setSpecifics({
                                                        collections: item.collection ? item.collection?.split(',') : [],
                                                        colors: ["TO BE REPLACED"]
                                                    })
                                                    .setVisualization(null)
                                                    .setSiblings(null)
                                                    .setSize({ width: width, height: height, unit: "cm" } as Size)
                                                    .setIsDefault(false)
                                                    .setProductColors([]);
                                        }))
                            
                    }).flat(5)
                    setProducts(mappedProducts)
                }
            })
        }
    };

    const generateSeamless = async (editProduct: EditProduct, variant) => {
        const body = {
            plankWidth: variant.getSize().width,
            plankHeight: variant.getSize().height,
            pattern: variant.getPattern(),
            files: editProduct.images[variant.getColor()]
        }
        const selectedVariantIndex = editProduct.variants.findIndex(v => v === variant)
        try{
            editProduct.variants[selectedVariantIndex].setStatus("seamless_generation_pending")
            let data = await productsAPI.generateSeamlessForPattern(body, variant.getPattern());
            editProduct.variants[selectedVariantIndex].setSeamless64URL(data)
            editProduct.variants[selectedVariantIndex].setStatus("seamless_generation_done")
            return editProduct
        }catch(e) {
            editProduct.variants[selectedVariantIndex].setStatus("seamless_generation_error")
            return editProduct
        }
    }

    const getImageDimensions = (file) => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          const objectUrl = URL.createObjectURL(file);
    
          // Set the source of the image
          image.src = objectUrl;
    
          // Resolve the promise once the image is loaded
          image.onload = () => {
            resolve({
              width: image.width,
              height: image.height,
            });
    
            // Clean up the object URL
            URL.revokeObjectURL(objectUrl);
          };
    
          // Reject the promise if there's an error
          image.onerror = () => {
            reject(new Error('Error loading image'));
            URL.revokeObjectURL(objectUrl);
          };
        });
      };

      useEffect(() => {
        if(images && products && products.length > 0)
            prepareEditProducts()
      },[products, images])

    const prepareEditProducts = async() => {
        const EditProductsTemp: EditProduct[] = await Promise.all(products.map(async (product: Product) => {
            let editProduct = new EditProduct()
            await editProduct.mapExistingProductToEditProduct(product, vendorTheme.name)
            const file = images.find(image => image.name.includes(editProduct.name))
            if(file){
                const dimensions = await getImageDimensions(file); // Await the dimensions
                //@ts-ignore
                if(dimensions.width > dimensions.height && editProduct.sizes[0].width < editProduct.sizes[0].height){
                    editProduct.setSizes([{ width: editProduct.sizes[0].height, height: editProduct.sizes[0].width, unit: "cm" } as Size])
                }
                editProduct.setImages({
                    //@ts-ignore
                    default: [...images.filter(image => image.name.includes(editProduct.name))]
                })
            }
            return editProduct
        }))
        setEditProducts(EditProductsTemp)
    }

    const prepareSeamlessForProducts = async () => {
        setProcessingStarted(true);
        const EditProductsTemp: any[] = [];
    
        for (const editProduct of editProducts) {
            try {
                const updatedVariants = [];
    
                for (const variant of editProduct.variants) {
                    try {
                        const seamlessResult: EditProduct = await generateSeamless(editProduct, variant);
                        updatedVariants.push(seamlessResult);
                    } catch (error) {
                        console.error(`Error generating seamless for variant ${variant.getId()}:`, error);
                        // Continue with the next variant
                    }
                }
    
                EditProductsTemp.push(updatedVariants.flat(20));
    
            } catch (error) {
                console.error(`Error processing editProduct ${editProduct}:`, error);
                // Continue with the next editProduct
            }
        }
    
        setEditProducts(
            EditProductsTemp.flat(20).filter((item, index, self) =>
                index === self.findIndex((t) => t === item)
            )
        );
    };

    const handleImageChange = (event) => {
        const imageFiles = Array.from(event.target.files);
        setImages(imageFiles); // Store the selected images
    };

    const getRowBackground = (variant: ProductVariant) => {
        if(processingStarted)
            switch (variant.status) {
                case "seamless_generation_pending":
                    return "processing-row"
                case "seamless_generation_error":
                    return "error-row"
                case "seamless_generation_done":
                    return "done-row"
                default:
                    break;
            }
        else return "default-row"
    }

    const getRowProgress = (variant: ProductVariant) => {
        if(processingStarted)
            switch (variant.status) {
                case "seamless_generation_pending":
                    return <LinearProgress color="warning"  />
                case "seamless_generation_error":
                    return <LinearProgress color="error" variant="determinate" value={100} />
                case "seamless_generation_done":
                    return <LinearProgress color="success" variant="determinate" value={100}  />
                default:
                    break;
            }
        else return <></>
    }

    const saveProducts = async () => {
        for (const editProduct of editProducts) {
            const defaultVariant = editProduct.getDefaultVariant();

            if (editProduct.variants && editProduct.variants.length > 0 && defaultVariant) {
                try {
                    editProduct.variants.map(variant => variant.setStatus("seamless_generation_pending"))
                    await productsAPI.bulkUpdateProducts(
                        null,
                        {
                            removed: [],
                            modified: [],
                            created: editProduct.variants.map(variant =>
                                (new Product()).mapVariantIntoProduct(variant, editProduct).toPlainObject()
                            ),
                        },
                        editProduct.variants.filter((variant: ProductVariant) => !variant.status.includes("error"))
                            .map((variant: ProductVariant) => {
                                const blob = base64ToBlob(variant.getSeamless64URL());
                                const fileName = `image_${variant.productId}`;
                                const file = new File([blob], `${fileName}.png`, { type: 'image/png' });
                                return file;
                            })
                    );
                    editProduct.variants.map(variant => variant.setStatus("seamless_generation_done"))
                } catch (error) {
                    editProduct.variants.map(variant => variant.setStatus("seamless_generation_error"))
                    console.error(`Error processing product ${editProduct}:`, error);
                    // Continue even if one product fails
                }
            }
        }
    };

    return (
        <PageContainer title={t('SIDEBAR.SETTINGS.MIGRATIONS')}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Box borderRadius={2} paddingBlock={1} marginTop={1} border={"1px solid gray"} sx={{cursor: 'pointer'}} onClick={() => csvFileInput.current.click()}
                        bgcolor={"primary.main"} 
                        display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                            <img src={UploadIcon} style={{height: "45px", padding: "10px", 
                                filter: (theme.palette.mode === 'dark') ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)": ""}}/>
                                {theme.palette.mode !== 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" color={"primary.contrastText"}>{t('Upload CSV File')}</Typography>}
                                {theme.palette.mode === 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" color={"primary.text"}>{t('Upload CSV File')}</Typography>}
                            
                            <input
                                type="file"
                                onChange={handleFileChange}
                                accept=".csv"
                                ref={csvFileInput}
                                style={{display: 'none'}} 
                            />
                            {editProducts?.length > 0 && <Typography fontSize={14} letterSpacing={-0.5} fontWeight="bolder" color={"white"} marginTop={2} bgcolor={"black"} paddingInline={2}>CSV File loaded with {editProducts?.length} products</Typography>}
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box borderRadius={2} paddingBlock={1} marginTop={1} border={"1px solid gray"} sx={{cursor: 'pointer'}} onClick={() => imageFilesInput.current.click()}
                        bgcolor={"primary.main"} 
                        display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                            <img src={UploadIcon} style={{height: "45px", padding: "10px", 
                                filter: (theme.palette.mode === 'dark') ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)": ""}}/>
                                {theme.palette.mode !== 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" color={"primary.contrastText"}>{t('Upload Product Images')}</Typography>}
                                {theme.palette.mode === 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" color={"primary.text"}>{t('Upload Product Images')}</Typography>}
                            
                            <input
                                type="file"
                                onChange={handleImageChange}
                                multiple
                                accept="image/*"
                                ref={imageFilesInput}
                                style={{display: 'none'}} 
                            />
                            {images?.length > 0 && <Typography fontSize={14} letterSpacing={-0.5} fontWeight="bolder" color={"white"} marginTop={2} bgcolor={"black"} paddingInline={2}>{images?.length} picture loaded</Typography>}
                        </Box>
                    </Grid>
                </Grid>
                
                
                {editProducts.length > 0 && <Button variant='contained' color='secondary' onClick={() => prepareSeamlessForProducts()} sx={{marginTop: 2, background: "black"}}>
                    Generate seamless for these products
                </Button>}

                {editProducts.length > 0 && <Button variant='contained' color='secondary' onClick={() => saveProducts()} sx={{marginTop: 2, background: "black"}}>
                    Save products
                </Button>}

                {editProducts.length > 0 && (
                    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{background: "whitesmoke"}}>
                                    <TableCell sx={{paddingLeft: 1}}>{t('Product Name')}</TableCell>
                                    <TableCell>{t('Type')}</TableCell>
                                    <TableCell>{t('SKU')}</TableCell>
                                    <TableCell>{t('Color')}</TableCell>
                                    <TableCell>{t('Pattern')}</TableCell>
                                    <TableCell>{t('Dimensions')}</TableCell>
                                    <TableCell>{t('# pictures')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {editProducts.map((product: EditProduct, index) => (
                                    <>
                                    {product.variants.map((variant: ProductVariant, index) => (
                                        <TableRow key={index} sx={{position: "relative"}} className={getRowBackground(variant)}>
                                            <TableCell padding='none'><Typography variant="caption" marginLeft={1}>{product.name}</Typography></TableCell>
                                            <TableCell padding='none'><Typography variant="caption">{product.type}</Typography></TableCell>
                                            <TableCell padding='none'>{variant.getSku()}</TableCell>
                                            <TableCell padding='none'><Typography variant="caption">{variant.getColor()}</Typography></TableCell>
                                            <TableCell padding='none'><Typography variant="caption">{variant.getPattern()}</Typography></TableCell>
                                            <TableCell padding='none'><Typography variant="caption">{`${variant.getSize().width} x ${variant.getSize().height} ${variant.getSize().unit}`}</Typography></TableCell>
                                            <TableCell padding='none'><Typography variant="caption">{product.images?product.images["default"]?.length??"0":"0"} pictures</Typography></TableCell>
                                            <Box position={"absolute"} sx={{ bottom: 0, width: "100%", left: 0}}>
                                                {getRowProgress(variant)}
                                            </Box>
                                        </TableRow>
                                    ))}

                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Container>
        </PageContainer>
    );
};
