import { Edit } from "@mui/icons-material"
import { Box, Grid, InputAdornment, Skeleton, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { base64ToBlob, getBase64, imageUrlToBase64 } from "../../../../../common/utils/utils"
import UploadIcon from "../../../../../assets/icons/upload-icon.png"
import LoadingImage from "../../../../../assets/loader.gif"
import translate from "translate";
import * as _ from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { VendorState } from "../../../../../redux/vendor/rootReducer"
import { ImageDialog } from "../../components/step-3/upload-methods/product-images.component"
import { useTranslation } from "react-i18next"
import { useTheme } from "@emotion/react"
import { EditProduct } from "../../models/edit-product.model"
import { ProductVariant } from "../../models/variant.model"
import FadeIn from "react-fade-in/lib/FadeIn"
import { extractColors } from "extract-colors"
import { ntc } from "../../../../../ntc"

interface VariantRepresentationProps {
    editProduct: EditProduct,
    variant: ProductVariant,
    activeStep: number,
    setEditProduct,
    elRefs
}

export const VariantRepresentation = (props: VariantRepresentationProps) => {
    const {editProduct, variant, setEditProduct, elRefs, activeStep} = props
    const [t] = useTranslation()
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false)
    const [selectedImage, setSelectedImage] = useState<any>()
    const [isLoading, setIsLoading] = useState<Boolean>(false)

    const [fields, setFields] = useState({
        sku: {
            isTouched: false,
            label: `${t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.SKU")}*`,
            name: "sku"
        },
        seamlessHeight: {
            isTouched: false,
            label: `${t("PATTERNS.PARTIALS.TEXTURE_HEIGHT")}*`,
            name: "seamlessHeight"
        },
        seamlessWidth: {
            isTouched: false,
            label: `${t("PATTERNS.PARTIALS.TEXTURE_WIDTH")}*`,
            name: "seamlessWidth"
        }
    })

    const handleImageChange = async(event, id) => {
        const files = event.target.files;
        const path: string = await getBase64(files[0]);
        const base64: string = path.split(',')[1]
        const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
        editProduct.variants[selectedVariantIndex].setSeamless64URL(base64)
        editProduct.variants[selectedVariantIndex].setImageChanged(true)
        setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)))
    };

    const handleEditClick = (event, fileInputRef) => {
        handleOnProductClick(variant.getId())
        event.stopPropagation()
        fileInputRef.current.focus();
        fileInputRef.current.click();
    };

    const handleOnProductClick = (id) => {
        const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
        editProduct.variants[selectedVariantIndex].setIsSelected(!editProduct.variants[selectedVariantIndex].getIsSelected())
        setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)))
    }

    const handleSeamlessColorsChange = (value, id) => {
        const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
        editProduct.variants[selectedVariantIndex].setProductColors(value)
        setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)))
    }

    const handleSKUChange = (value, id) => {
        setFields({...fields, sku: {
            ...fields.sku,
            isTouched: true
        }})
        const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
        editProduct.variants[selectedVariantIndex].setSku(value)
        setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)))
    }

    const handleSeamlessWidthChange = (value, id) => {
        setFields({...fields, seamlessWidth: {
            ...fields.seamlessWidth,
            isTouched: true
        }})
        const reg = new RegExp('^$|^[0-9]+(\.[0-9]{0,2})?$')
        if(reg.test(value)){
            const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
            editProduct.variants[selectedVariantIndex].setSeamlessWidth(value)
            setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)))
        }
    }

    const handleSeamlessHeightChange = (value, id) => {
        setFields({...fields, seamlessHeight: {
            ...fields.seamlessHeight,
            isTouched: true
        }})
        const reg = new RegExp('^$|^[0-9]+(\.[0-9]{0,2})?$')
        if(reg.test(value)){
            const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
            editProduct.variants[selectedVariantIndex].setSeamlessHeight(value)
            setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)))
        }
    }

    const handleSeamless64Change = (value, id) => {
        const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
        editProduct.variants[selectedVariantIndex].setSeamless64URL(value)
        editProduct.variants[selectedVariantIndex].setImageChanged(true)
        setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)))
    }

    const isNotValidSKU = (id) => {
        const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
        return fields.sku.isTouched && editProduct.variants[selectedVariantIndex].getIsSelected() && (editProduct.variants[selectedVariantIndex].getSku() === "" || !editProduct.variants[selectedVariantIndex].getSku())
    }
    const isNotValidSeamlessWidth = (id) => {
        const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
        return fields.seamlessWidth.isTouched && editProduct.variants[selectedVariantIndex].getIsSelected() && (editProduct.variants[selectedVariantIndex].getSeamlessWidth() === "0" || !editProduct.variants[selectedVariantIndex].getSeamlessWidth())
    }
    const isNotValidSeamlessHeight = (id) => {
        const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
        return fields.seamlessHeight.isTouched && editProduct.variants[selectedVariantIndex].getIsSelected() && (editProduct.variants[selectedVariantIndex].getSeamlessHeight() === "0"  || !editProduct.variants[selectedVariantIndex].getSeamlessHeight())
    }

    const closeDialog = () => {
        setIsOpen(false)
    }

    const getSeamlessColors = () => {
        translate.engine = "google"; 

        const reader = new FileReader();
        reader.onload = async() => {
            const imageUrl = reader.result;
            // Extract colors from the image URL
            const colorsArray = await extractColors(imageUrl as string, { distance: 0.2, saturationDistance: 0.5, lightnessDistance: 0.1 })
            const hexCodes = await await Promise.all(colorsArray.map(async(e) => e.hex))
            handleSeamlessColorsChange(hexCodes, variant.getId())
        };
        reader.readAsDataURL(base64ToBlob(variant.getSeamless64URL()));
    }

    useEffect(() => {
        getSeamlessColors()
    }, [variant.getSeamless64URL()])

    return <Grid item xs={12} sm={12} md={6} >
        <Box display={"flex"} bgcolor={"primary.main"} borderRadius={2} sx={{cursor: "pointer", border: variant.getIsSelected() ? "2px solid black":"", borderColor: "secondary.main", position: "relative" }} onClick={() => handleOnProductClick(variant.getId())}>
            {variant.getIsSelected() && <Typography fontSize={12} color={"white"} letterSpacing={0} position={"absolute"} right={0} top={0} bgcolor={"secondary.main"} paddingInline={2} paddingBlock={0.5} sx={{borderTopRightRadius: 2}}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.MESSAGES.SELECTED")}</Typography>}
            
            <Box padding={1} display={"flex"}>
                <Box display={"flex"} flex={0} flexDirection={"column"} marginRight={1}>{variant.getProductColors().map((hex, index) => (
                    <Box bgcolor={hex} width={"20px"} height={"20px"} key={index}></Box>
                ))}</Box>
                {isLoading && <Box zIndex={10000} 
                            display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}
                            sx={{userSelect: "none"}} borderRadius={"5px"}
                            width={"120px"} height={"120px"} bgcolor={"#00000060"} top={0} left={0}>
                    <img src={LoadingImage} width={"30px"}/>
                    <Typography fontSize={14} letterSpacing={-0.5} 
                                color={"primary.light"} fontWeight={"bolder"} textTransform={'capitalize'}>Generating...</Typography>
                </Box>}
                {!isLoading && variant.getSeamless64URL() && <FadeIn><Box position={"relative"}>
                    <img src={`data:image/png;base64,${variant.getSeamless64URL()}`} 
                        style={{width: "120px", borderRadius: "5px", cursor: "pointer"}} onClick={(e) => {
                            e.stopPropagation()
                            setSelectedImage(variant.getSeamless64URL())
                            setIsOpen(true)
                        }}/>
                    <Edit 
                        sx={{position: "absolute", top: 5, right: 5, backgroundColor: "primary.main", color: "primary.text", padding: 0.5, borderRadius: "50%", fontSize: "14pt"}}
                        onClick={(event) => handleEditClick(event, elRefs[editProduct.variants.findIndex(v => v===variant)] )}
                        />
                    <input
                        type="file"
                        onChange={(event) => handleImageChange(event, variant.getId())}
                        multiple
                        accept="image/*"
                        ref={elRefs[editProduct.variants.findIndex(v => v===variant)]}
                        style={{display: 'none'}} 
                    />
                </Box></FadeIn>}
                {!isLoading && !variant.getSeamless64URL() && <Box zIndex={10000} 
                            display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}
                            sx={{userSelect: "none"}} borderRadius={"5px"}
                            onClick={(event) => handleEditClick(event, elRefs[editProduct.variants.findIndex(v => v===variant)] )}
                            width={"120px"} height={"120px"} bgcolor={"#00000060"} top={0} left={0}>
                        <img src={UploadIcon} style={{width: "30px", padding: "10px", 
                            //@ts-ignore
                            filter: (theme.palette.mode !== 'dark') ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)": ""}}
                        />
                        <input
                            type="file"
                            onChange={(event) => handleImageChange(event, variant.getId())}
                            multiple
                            accept="image/*"
                            ref={elRefs[editProduct.variants.findIndex(v => v===variant)]}
                            style={{display: 'none'}} 
                        />
                    <Typography fontSize={14} letterSpacing={-0.5} textAlign={"center"} marginTop={-1.5}
                                color={"primary.light"} fontWeight={"bolder"} textTransform={'capitalize'}>Upload seamless</Typography>
                </Box>
                }
            </Box>
            <Box padding={1}>
                <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} textTransform={'capitalize'} marginRight={3}>{editProduct.brand} </Typography>
                <Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} marginBlock={0.5}>{variant.getTitle()}</Typography>
                <Typography fontSize={12} color={"primary.text"} letterSpacing={0} textTransform={'capitalize'} marginBottom={2}>{editProduct.description} </Typography>
                <table>
                    <thead>
                        <tr>
                            <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginRight={3}>{fields.sku.label}</Typography></td>
                            <td>
                                <TextField
                                    error={isNotValidSKU(variant.getId())}
                                    name="SKU"
                                    fullWidth
                                    size='small'
                                    color='secondary'
                                    autoComplete='off'
                                    value={variant.getSku() || ''}
                                    onChange={(e) => handleSKUChange(e.target.value, variant.getId())}
                                    onClick={(e) => e.stopPropagation()}
                                    InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "9pt", color: "primary.contrastText", width: "150px" } }}
                                    helperText={isNotValidSKU(variant.getId()) ? "SKU is required": ""}
                                    inputProps={{
                                        style: {
                                        padding: 0,
                                        paddingLeft: 4,
                                        border: "0",
                                        borderRadius: 0
                                        }
                                    }}
                                    FormHelperTextProps={{ style: {  marginTop: 0, marginLeft: 0, fontSize: "8pt", color: "primary.contrastText" } }}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginRight={3}>{fields.seamlessHeight.label}</Typography></td>
                            <td>
                                <TextField
                                    error={isNotValidSeamlessHeight(variant.getId()) }
                                    name="seamlessHeight"
                                    fullWidth
                                    size='small'
                                    color='secondary'
                                    autoComplete='off'
                                    type="string"
                                    value={variant.getSeamlessHeight() || ''}
                                    onChange={(e) => handleSeamlessHeightChange(e.target.value, variant.getId())}
                                    onClick={(e) => e.stopPropagation()}
                                    InputProps={{ 
                                        endAdornment: <InputAdornment position="end">
                                                <Typography fontSize={12} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} >m</Typography>
                                            </InputAdornment>,
                                        sx: {  backgroundColor: "primary.main", fontSize: "9pt", color: "primary.contrastText", width: "150px" } }}
                                    helperText={isNotValidSeamlessHeight(variant.getId()) ? t("PAGES.NEW_PRODUCT.GENERAL_INFO.ERRORS.FIELD_IS_REQUIRED"): ""}
                                    inputProps={{
                                        style: {
                                            padding: 0,
                                            paddingLeft: 4,
                                            border: "0",
                                            borderRadius: 0
                                        }
                                    }}
                                    FormHelperTextProps={{ style: {  marginTop: 0, marginLeft: 0, fontSize: "8pt", color: "primary.contrastText" } }}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginRight={3}>{fields.seamlessWidth.label}</Typography></td>
                            <td>
                                <TextField
                                    error={isNotValidSeamlessWidth(variant.getId()) }
                                    name="seamlessWidth"
                                    fullWidth
                                    size='small'
                                    color='secondary'
                                    autoComplete='off'
                                    type="string"
                                    value={variant.getSeamlessWidth() || ''}
                                    onChange={(e) => handleSeamlessWidthChange(e.target.value, variant.getId())}
                                    onClick={(e) => e.stopPropagation()}
                                    InputProps={{ 
                                        endAdornment: <InputAdornment position="end">
                                                <Typography fontSize={12} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} >m</Typography>
                                            </InputAdornment>,
                                        sx: {  backgroundColor: "primary.main", fontSize: "9pt", color: "primary.contrastText", width: "150px" } }}
                                    helperText={isNotValidSeamlessWidth(variant.getId()) ? t("PAGES.NEW_PRODUCT.GENERAL_INFO.ERRORS.FIELD_IS_REQUIRED"): ""}
                                    inputProps={{
                                        style: {
                                            padding: 0,
                                            paddingLeft: 4,
                                            border: "0",
                                            borderRadius: 0,
                                        }
                                    }}
                                    FormHelperTextProps={{ style: {  marginTop: 0, marginLeft: 0, fontSize: "8pt", color: "primary.contrastText" } }}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginRight={3}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.APPLICATION")}</Typography></td>
                            <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} textTransform={'capitalize'} >{editProduct.application_types}</Typography></td>
                        </tr>
                        {variant.getColor() !== "default" && <tr>
                            <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginRight={3}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.COLOR")}</Typography></td>
                            <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} textTransform={'capitalize'} >{variant.getColor()}</Typography></td>
                        </tr>}
                        <tr>
                            <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginRight={3}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.SIZE")} </Typography></td>
                            <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} >{variant.getSize()?.width}{variant.getSize()?.unit} x {variant.getSize()?.height}{variant.getSize()?.unit}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginRight={3}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.PATTERN")} </Typography></td>
                            <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} textTransform={'capitalize'} >{variant.getPattern()}</Typography></td>
                        </tr>
                    </thead>
                </table>
            </Box>
        </Box>
        <ImageDialog base64Image={selectedImage} image={null} isOpen={isOpen} closeDialog={closeDialog} />
    </Grid>
}