export const routes = {
    auth: {
      me: "/users/me",
      login: "/user/signin",
      refreshTokens: "/user/refresh",
    },
    products: {
      findAll: "/services/pms/products",
      findOne: (id: string) => `/services/pms/products/${id}`,
      deleteOne: (id: string) => `/services/pms/products/${id}`,
      bulkUpdate: "/services/pms/products/bulk",
      generateSeamlessForPattern: (pattern: string) => `/services/pms/products/seamless-${pattern}`
    },
    users: {
      sendVerificationEmail: "/users/verify-email",
      verifyToken: "/users/verify-token",
      signup: "/users/signup",
      findAll: "/users",
      create: "/users",
      patch: "/users",
      findOne: (id: string) => `/users/${id}`,
      deleteOne: (id: string) => `/users/${id}`,
    },
    vendors: {
      findAll: "/services/pms/vendors",
      findOne: (id: string) => `/services/pms/vendors/${id}`,
      deleteOne: (id: string) => `/services/pms/vendors/${id}`,
      create: "/services/pms/vendors",
      patch: (id: string) => `/services/pms/vendors/${id}`,
      findAreas: "/services/pms/vendors/areas",
    },
    spaces: {
      findAll: "/services/pms/spaces",
      findOne: (id: string) => `/services/pms/spaces/${id}`,
      deleteOne: (id: string) => `/services/pms/spaces/${id}`,
      create: "/services/pms/spaces",
      patch: "/services/pms/spaces",
      analysis: "/analysis",
      patchMasks: (id: string) => `/services/pms/spaces/${id}/masks`,
      findAllSpaceTypes: "/services/pms/spaceTypes",
      assignSpaces: "/services/pms/spaces/assign",
    },
    analytics: {
      findAll: "/services/pms/vendors/stats",
      search: "/services/pms/events/search",
    },
    settings: {
      findConfig: "/services/pms/config",
      patch: "/services/pms/config"
    },
  };