import { Alert, Autocomplete, Box, Divider, Grid, TextField, Typography } from "@mui/material"
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import CeilingIcon from     "../../../../../assets/icons/products/ceiling-icon.png"
import WallsIcon from       "../../../../../assets/icons/products/walls-icon.png"
import FloorIcon from       "../../../../../assets/icons/products/floor-icon.png"
import RugIcon from         "../../../../../assets/icons/products/rug-icon.png"
import FadeIn from "react-fade-in/lib/FadeIn";
import { ApplicationType } from "../../components/step-1/application-type.component";
import { useDispatch, useSelector } from "react-redux";
import { setEditProductStepToValidate } from "../../../../../redux/vendor/products/productsActions";
import { VendorState } from "../../../../../redux/vendor/rootReducer";
import { EditProduct } from "../../models/edit-product.model";
import { APPLICATION_TYPE } from "../../../my-products/models/product.model";
import * as _ from "lodash"
import { isValidURL } from "../../../../../common/utils/utils";
import { useProductsApi } from "../../../../../common/hooks/use-products-api.hook";
import { areas, useSettingsApi } from "../../../../../common/hooks/use-settings-api.hook";

interface EditProductProps {
    editProduct: EditProduct
    setEditProduct,
    activeStep: number
}

const staticAreas = [APPLICATION_TYPE.WALLS, APPLICATION_TYPE.FLOOR, APPLICATION_TYPE.CEILING];
const interactiveAreas = [APPLICATION_TYPE.INTERACTIVE_FLOOR];

export const ProductGeneralInformationForm = (props: EditProductProps) => {
    const [t] = useTranslation();
    const {editProductStepToValidate, products} = useSelector((state: VendorState) => state.products);
    const dispatch = useDispatch()
    const [vendorAreas, setVendorAreas] = useState<any>([])
    const [collectionsOptions, setCollectionsOptions] = useState([])
    const productsAPI = useProductsApi()
    const settingsAPI = useSettingsApi()

    useEffect(() => {
        dispatch(setEditProductStepToValidate(-1))
        if(staticAreas.find(area => vendorAreas?.includes(area.toLowerCase()))){
            handleIsStaticChange()
        }
    }, [vendorAreas])


    useEffect(() => {
        if(!products || products.length === 0){
            productsAPI.refreshProductsList()
        } else {
            setCollectionsOptions([
                ...new Set(
                  products
                    .filter(product => product.specifics && product.specifics.collections) 
                    .flatMap(product => product.specifics.collections)
                )
            ])
        }
    }, [products])

    useEffect(() => {
        if(areas){
            setVendorAreas(areas)
        }
    }, [areas])

    const [fields, setFields] = useState({
        name: {
            isTouched: false,
            label: t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.PRODUCT_NAME"),
            name: "_name"
        },
        description: {
            isTouched: false,
            label: t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.DESCRIPTION"),
            name: "_description"
        },
        brand: {
            isTouched: false,
            label: t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.BRAND"),
            name: "_brand"
        },
        collections: {
            isTouched: false,
            label: t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.COLLECTIONS"),
            name: "_collections"
        },
        link: {
            isTouched: false,
            label: t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.LINK"),
            name: "_link"
        },
    })

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields({...fields, [name]: {
            ...fields[name],
            isTouched: true
        }})
        props.setEditProduct(_.cloneDeep(props.editProduct.setProperty(name, value)))
    }

    const handleAutocompleteChange = (event, newValue) => {
        if (typeof newValue === "string") {
          setCollectionsOptions([...collectionsOptions, newValue]);
        } else if (newValue && newValue.inputValue) {
          setCollectionsOptions([...collectionsOptions, newValue.inputValue]);
        }
        props.setEditProduct(_.cloneDeep(props.editProduct.setProperty("_collections", newValue)));
    };

    const isValidInput = (name, field) => {
        return (editProductStepToValidate === props.activeStep && props.editProduct && !props.editProduct[field]) ||
                (fields && fields[name].isTouched && !props.editProduct[field])
    }

    const handleIsStaticChange = () => {
        props.setEditProduct(_.cloneDeep(props.editProduct.toggleIsStatic()))
    }

    return  <Box bgcolor={"primary.light"} padding={2} borderRadius={3} position={"relative"} display={"flex"} flexDirection={"column"}>
                <Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginBottom={2}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.TITLE")}</Typography>
                <Divider />
                <Grid container columnSpacing={3} rowSpacing={1} >
                    <Grid item md={12} xs={12}>
                        <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} marginTop={2}>{fields.name.label}</Typography>
                        <TextField
                                error={isValidInput("name", "name")}
                                name={fields.name.name}
                                value={props.editProduct.name || ''}
                                onChange={handleChange}
                                color="secondary"
                                fullWidth
                                autoComplete='off'
                                required
                                size='small'
                                InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                            />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Typography fontSize={14} letterSpacing={0} color={"primary.contrastText"}>{fields.description.label}</Typography>
                        <TextField
                            error={isValidInput("description", "description")}
                            name={fields.description.name}
                            value={props.editProduct.description || ''}
                            onChange={handleChange}
                            color="secondary"
                            fullWidth
                            autoComplete='off'
                            multiline
                            rows={4}
                            size='small'
                            InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                            />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Typography fontSize={14} letterSpacing={0} color={"primary.contrastText"}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.COLLECTIONS")}</Typography>
                        <Autocomplete
                            multiple
                            freeSolo
                            options={collectionsOptions.filter(option => !props.editProduct.collections?.includes(option))} 
                            value={props.editProduct.collections || []}
                            onChange={handleAutocompleteChange}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                error={isValidInput("collections", "collections")}
                                color="secondary"
                                fullWidth
                                size="small"
                                required
                                InputProps={{
                                ...params.InputProps,
                                sx: { backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" }
                                }}
                            />
                            )}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0}>{fields.link.label}</Typography>
                        <TextField
                                error={(props.editProduct.link && !isValidURL(props.editProduct.link))}
                                name={fields.link.name}
                                value={props.editProduct.link || ''}
                                onChange={handleChange}
                                type="url"
                                color="secondary"
                                fullWidth
                                autoComplete='off'
                                required
                                size='small'
                                helperText={(props.editProduct.link && !isValidURL(props.editProduct.link))?t("PAGES.NEW_PRODUCT.GENERAL_INFO.ERRORS.NOT_A_LINK"):""}
                                InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                            />
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Typography fontSize={14} letterSpacing={0} color={"primary.contrastText"} marginTop={2}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.APPLICATION")}</Typography>
                        <Box display={"flex"} marginTop={0.5}>
                            {vendorAreas?.find(area => staticAreas.includes(area.toLowerCase())) 
                                && <Box bgcolor={props.editProduct.isStatic ? "secondary.main": "primary.main"} paddingInline={2} paddingBlock={1} marginInline={1} borderRadius={2} border={"1px solid gray"} 
                                    borderColor={props.editProduct.isStatic ? "transparent":"gray"} sx={{cursor: "pointer"}} 
                                    onClick={handleIsStaticChange}>
                                <Typography fontSize={14} color={props.editProduct.isStatic?"white":"primary.text"} letterSpacing={0} >{t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.STATIC")}</Typography>
                            </Box>}
                            {vendorAreas?.find(area => interactiveAreas.includes(area.toLowerCase()))
                                && <Box bgcolor={!props.editProduct.isStatic ? "secondary.main": "primary.main"} paddingInline={2} paddingBlock={1} marginInline={1} borderRadius={2} border={"1px solid gray"} 
                                    borderColor={!props.editProduct.isStatic ? "transparent":"gray"} sx={{cursor: "pointer"}} 
                                    onClick={handleIsStaticChange}>
                                <Typography fontSize={14} color={!props.editProduct.isStatic?"white":"primary.text"} letterSpacing={0} >{t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.INTERACTIVE")}</Typography>
                            </Box>}
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Typography fontSize={14} letterSpacing={0} color={"primary.contrastText"}>{fields.brand.label}</Typography>
                        <TextField
                                error={isValidInput("brand", "brand")}
                                name={fields.brand.name}
                                value={props.editProduct.brand || ''}
                                onChange={handleChange}
                                autoComplete='off'
                                color="secondary"
                                fullWidth
                                required
                                size='small'
                                InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                            />
                    </Grid>
                    <Grid item md={12} xs={12} marginBlock={2} overflow={"auto"}>
                        {props.editProduct.isStatic && <FadeIn >
                            <Box display={"flex"} justifyContent={"center"} >
                                {vendorAreas?.map((area, index) => (<Fragment key={index}>
                                    {area.toLowerCase() === APPLICATION_TYPE.FLOOR && <ApplicationType {...props}
                                                        application_type={APPLICATION_TYPE.FLOOR}
                                                        icon={FloorIcon}
                                                        />}
                                    {area.toLowerCase() === APPLICATION_TYPE.WALLS && <ApplicationType {...props}
                                                        application_type={APPLICATION_TYPE.WALLS}
                                                        icon={WallsIcon}
                                                        />}
                                    {/* {area.toLowerCase() === APPLICATION_TYPE.CEILING && <ApplicationType {...props}
                                                        application_type={APPLICATION_TYPE.CEILING}
                                                        icon={CeilingIcon}
                                                        />} */}
                                    </Fragment>
                                ))}
                                
                            </Box>
                        </FadeIn>}
                        {!props.editProduct.isStatic &&  <FadeIn >
                            <Box display={"flex"} justifyContent={"center"} >
                            {vendorAreas?.map((area, index) => (<Fragment key={index}>
                                {area.toLowerCase() === APPLICATION_TYPE.INTERACTIVE_FLOOR && <ApplicationType {...props}
                                                    application_type={APPLICATION_TYPE.INTERACTIVE_FLOOR}
                                                    icon={RugIcon}
                                                    />}
                            </Fragment>))}
                            </Box>
                        </FadeIn>}
                    </Grid>
                </Grid>
                {props.editProduct.application_types.length === 0 && <Alert severity="error" sx={{marginTop: 2}}>{t('PAGES.NEW_PRODUCT.GENERAL_INFO.ERRORS.NO_APPLICATION_SELECTED')}</Alert>}
            </Box>
}
