import { Delete, Edit } from '@mui/icons-material';
import { Typography, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, TablePagination, Chip, TableSortLabel, useMediaQuery, Box } from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '../models/product.model';
import { ProductView } from './product-view.component';

export const ProductsAsList = (props) => {
    const [t, i18n] = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');

    const [selectedProductIdForView, setSelectedProductIDForView] = useState<any>()

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSort = (property) => {
        const isAsc = sortBy === property && sortOrder === 'asc';
        setSortBy(property);
        setSortOrder(isAsc ? 'desc' : 'asc');
        const sortedProducts = [...props.filteredProducts].sort((a, b) => {
            if (isAsc) {
                return a[property] > b[property] ? 1 : -1;
            } else {
                return a[property] < b[property] ? 1 : -1;
            }
        });
        props.setFilteredProducts(sortedProducts);
    };

    return (<>
            <TableContainer component={Paper} sx={{borderRadius: 4}}>
                <Table size="small" sx={{bgcolor: "primary.light"}}>
                    <TableHead>
                        <TableRow >
                            <TableCell sx={{fontWeight: "bolder"}}></TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>{t('PAGES.MY_PRODUCTS.TABLE.H_NAME')}</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>{t('PAGES.MY_PRODUCTS.TABLE.H_SKU')}</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>{t('PAGES.MY_PRODUCTS.TABLE.H_BRAND')}</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>{t('PAGES.MY_PRODUCTS.TABLE.H_AVAILABILITY')}</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>{t('PAGES.MY_PRODUCTS.TABLE.H_TYPE')}</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>{t('PAGES.MY_PRODUCTS.TABLE.H_SIZE')}</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.filteredProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product: Product, index) => (
                            <Fragment key={index}><TableRow sx={{borderTop: `${index === 0?"2px":"2px"} solid gray`}}>
                                <TableCell sx={{cursor: "pointer"}} onClick={() => {setSelectedProductIDForView(product.id)}}>
                                    <img src={product.thumbnailPath} width={"30px"} height={"30px"} style={{borderRadius: 8}}></img>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{product.name}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{product.sku}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{product.brand}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>Available</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{product.application_types.map(type => <Typography variant="caption" key={type}><Chip size='small' label={t('PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.'+type.toUpperCase())} sx={{fontSize: "8pt", textTransform: "capitalize"}}/></Typography>)}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{product.width}x{product.length}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Edit onClick={() => props.handleEditProduct(product)} style={{color: "linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)", width: "20px"}}/> 
                                    <Delete onClick={() => props.onDelete(product)} style={{color: "linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)", width: "20px"}}/> 
                                </TableCell>
                            </TableRow>
                            {product.siblings && product.siblings.length > 0 && <TableRow key={index} sx={{backgroundColor: "primary.dark"}}>
                                <TableCell colSpan={8}>
                                    <Box display={"flex"} marginBlock={0} marginInline={2} >
                                        {product.siblings.map(sibling => (<Box marginRight={1} key={sibling.id} sx={{cursor: "pointer"}} onClick={() => {setSelectedProductIDForView(sibling.id)}}>
                                            <img src={sibling.thumbnailPath} width={"25px"} height={"25px"} style={{borderRadius: 6}}></img>
                                        </Box>))}
                                    </Box>
                                </TableCell>
                            </TableRow>}
                            </Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedProductIdForView && <ProductView selectedProductForViewID={selectedProductIdForView} setSelectedProductForView={setSelectedProductIDForView} />}
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={props.filteredProducts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
    </>)
}