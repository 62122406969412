import { useDispatch } from "react-redux";
import { routes } from "../../assets/constants";
import { ApiMethod } from "../models/api-types.type";
import { buildQueryParams } from "../utils/utils";
import { useAuthContext } from "./use-auth-context.hook";
import { setProducts } from "../../redux/vendor/products/productsActions";
import { Product } from "../../modules/vendor/my-products/models/product.model";

export const useProductsApi = () => {
    const { sendAuthGuardedRequest } = useAuthContext();
    const dispatch = useDispatch()

    const refreshProductsList = async() => {
      dispatch(setProducts(await findAllProducts()))
    }

    const findAllProducts = async (
      limit?: number,
      offset?: number,
    ): Promise<any> => {
      const queryString = buildQueryParams([
        { key: "limit", value: limit?.toString()??"0" },
        { key: "offset", value: offset?.toString()??"600" },
      ]);
  
      const response: any = await sendAuthGuardedRequest(
        ApiMethod.GET,
        routes.products.findAll + queryString,
      )
      return response.data
    };

    const findOneProduct = async (id: string): Promise<any> => {
      const response: any = await sendAuthGuardedRequest(
        ApiMethod.GET, 
        routes.products.findOne(id)
      )
      const product = new Product()
      return product.prepareProduct(response)
    };
  
    const findOneProductAndDelete = async (id: string): Promise<any> => {
      await sendAuthGuardedRequest(
        ApiMethod.DELETE, 
        routes.products.findOne(id)
      )
    };

    const bulkUpdateProducts = async (defaultProductId, differences, files): Promise<Product> => {
      let data = new FormData();
      data.append('data', JSON.stringify(differences))
      if(defaultProductId)
          data.append('defaultProductId', defaultProductId)

      const filesRead = files.map(file => 
          data.append("image", file)
      )
      await Promise.all(filesRead)

      const response = await sendAuthGuardedRequest(
        ApiMethod.POST, 
        routes.products.bulkUpdate,
        data,
        {
          //@ts-ignore
          maxBodyLength: Infinity,
          timeout: 2 * 60 * 1000,
        }
      )
      return (new Product()).prepareProduct(response)
    };

    const generateSeamlessForPattern = async (body, pattern) => {
      let data = new FormData();
      if(body.plankWidth)
          data.append('plankWidth', body.plankWidth);
      if(body.plankHeight)
          data.append('plankHeight', body.plankHeight);
      data.append('textureWidth', body.textureWidth ?? '4');
      data.append('textureHeight', body.textureHeight ?? '4');
      if(body.pattern)
          data.append('pattern', body.pattern);
      if(body.octoImageHeight)
          data.append('octoImageHeight', body.octoImageHeight);
      if(body.squareImageHeight)
          data.append('squareImageHeight', body.squareImageHeight);
      if(body.groutLineWidth)
          data.append('groutLineWidth', body.groutLineWidth);
      if(body.groutLineColor)
          data.append('groutLineColor', body.groutLineColor);
      if(body.lengthL1)
          data.append('lengthL1', body.lengthL1);
      if(body.lengthL2)
          data.append('lengthL2', body.lengthL2);
      if(body.lengthL3)
          data.append('lengthL3', body.lengthL3);
      let filePromises = []
      let octoPromises = []
      let squarePromises = []
      if(body.files)
          filePromises = body.files.map((file, index) => {
              return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.readAsArrayBuffer(file);
                  reader.onload = () => {
                      const blob = new Blob([reader.result], { type: file.type });
                      data.append("files", blob, "file" + index + ".jpg");
                      resolve(true);
                  };
                  reader.onerror = reject;
              });
      });

      if(body.imageA){
          filePromises.push(new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsArrayBuffer(body.imageA);
              reader.onload = () => {
                  const blob = new Blob([reader.result], { type: body.imageA.type });
                  data.append("imageA", blob, body.imageA.name);
                  resolve(true);
              };
              reader.onerror = reject;
          }))
          
      }
      
      if(body.imageB){
          filePromises.push(new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsArrayBuffer(body.imageB);
              reader.onload = () => {
                  const blob = new Blob([reader.result], { type: body.imageB.type });
                  data.append("imageB", blob, body.imageB.name);
                  resolve(true);
              };
              reader.onerror = reject;
          }))
      }

      if(body.imageC){
          filePromises.push(new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsArrayBuffer(body.imageC);
              reader.onload = () => {
                  const blob = new Blob([reader.result], { type: body.imageC.type });
                  data.append("imageC", blob, body.imageC.name);
                  resolve(true);
              };
              reader.onerror = reject;
          }))
      }
      if(body.imageD){
          filePromises.push(new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsArrayBuffer(body.imageD);
              reader.onload = () => {
                  const blob = new Blob([reader.result], { type: body.imageD.type });
                  data.append("imageD", blob, body.imageD.name);
                  resolve(true);
              };
              reader.onerror = reject;
          }))
      }

      if(body.octoImages)
          octoPromises = body.octoImages.map((file, index) => {
              return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.readAsArrayBuffer(file);
                  reader.onload = () => {
                      const blob = new Blob([reader.result], { type: file.type });
                      data.append("octoImages", blob, "file_ocot_" + index + ".jpg");
                      resolve(true);
                  };
                  reader.onerror = reject;
              });
          });

      if(body.squareImages)
          squarePromises = body.squareImages.map((file, index) => {
              return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.readAsArrayBuffer(file);
                  reader.onload = () => {
                      const blob = new Blob([reader.result], { type: file.type });
                      data.append("squareImages", blob, "file_square_" + index + ".jpg");
                      resolve(true);
                  };
                  reader.onerror = reject;
              });
          });
      
      await Promise.all(filePromises);
      await Promise.all(octoPromises);
      await Promise.all(squarePromises);

      const response = await sendAuthGuardedRequest(
        ApiMethod.POST, 
        routes.products.generateSeamlessForPattern(pattern),
        data,
        {
          //@ts-ignore
          maxBodyLength: Infinity,
          timeout: 2 * 60 * 1000,
          responseType: 'arraybuffer', // Ensure the response is handled as a binary buffer
        }
      )
      const uint8Array = new Uint8Array(response as any);
      let base64String = '';
      const chunkSize = 0x8000; // 32 KB chunks
      for (let i = 0; i < uint8Array.length; i += chunkSize) {
          base64String += String.fromCharCode.apply(null, Array.from(uint8Array.subarray(i, i + chunkSize)));
      }
      return btoa(base64String);
    }
  
    return { 
      refreshProductsList, 
      findOneProduct, 
      findOneProductAndDelete, 
      bulkUpdateProducts, 
      generateSeamlessForPattern 
    };
  };
  