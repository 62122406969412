import { useDispatch } from "react-redux";
import { routes } from "../../assets/constants";
import { ApiMethod } from "../models/api-types.type";
import { buildQueryParams } from "../utils/utils";
import { useAuthContext } from "./use-auth-context.hook";
import { User } from "../models/user.model";
import { useApi } from "./use-api.hook";

export const useUsersApi = () => {
    const { sendAuthGuardedRequest,  } = useAuthContext();
    const dispatch = useDispatch()

    const useAPI = useApi()

    const sendVerificationEmail = async (
      email: string
    ): Promise<any> => {
      const response: any = await useAPI.sendRequest(
        ApiMethod.POST,
        routes.users.sendVerificationEmail,
        {email}
      )
      console.log(response)

      if (!response.success) {
        throw new Error(response.message || 'Failed to send verification email');
      }
      return response
    };

    const sendVerifyToken = async (
        email: string,
        token: string
      ): Promise<any> => {
        const response: any = await useAPI.sendRequest(
          ApiMethod.POST,
          routes.users.verifyToken,
          { email, token }
        )
        if (!response.success) {
            throw new Error(response.message || 'Failed to verify token');
        }
        return response
    };

    const sendSetPassword = async (
        email: string,
        password: string,
        token: string
      ): Promise<any> => {
        const response: any = await useAPI.sendRequest(
          ApiMethod.POST,
          routes.users.signup,
          { email, token, password }
        )
        if (!response) {
            throw new Error(response.message || 'Failed to set password');
        }
        return response
    };

    const findAllUsers = async (
        limit?: number,
        offset?: number,
      ): Promise<any> => {
        const queryString = buildQueryParams([
          { key: "limit", value: limit?.toString() },
          { key: "offset", value: offset?.toString() },
        ]);
    
        const response: any = await sendAuthGuardedRequest(
          ApiMethod.GET,
          routes.users.findAll + queryString,
        )
        return response
    };

    const findOneUserAndDelete = async (id: string): Promise<any> => {
        await sendAuthGuardedRequest(
          ApiMethod.DELETE, 
          routes.users.deleteOne(id)
        )
    };

    const createUser = async (user): Promise<any> => {
        return await sendAuthGuardedRequest(
          ApiMethod.POST, 
          routes.users.create,
          user
        )
    }; 

    const updateUser = async (user): Promise<any> => {
        return await sendAuthGuardedRequest(
          ApiMethod.PATCH, 
          routes.users.patch,
          user
        )
    }; 

    const findOneUser = async (id): Promise<any> => {
        return (await sendAuthGuardedRequest(
          ApiMethod.GET, 
          routes.users.findAll,
        ) as User[])?.find((user: User) => user._id === id)
    };
    
    
    return { 
        sendVerificationEmail,
        sendVerifyToken,
        sendSetPassword,
        findAllUsers,
        findOneUserAndDelete,
        createUser,
        updateUser,
        findOneUser
    };


};
  