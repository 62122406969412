import { createContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../../common/components/PageContainer';

import { ProductPreview } from '../../../modules/vendor/edit-product/components/product-preview/product-preview.component';
import { ProductGeneralInformationForm } from '../../../modules/vendor/edit-product/forms/step-1/product-general-information.form';
import { ProductVariantsAndTypesForm } from '../../../modules/vendor/edit-product/forms/step-2/product-variants-and-types.form';
import { EditProductStepper } from '../../../modules/vendor/edit-product/components/stepper/edit-product.stepper.component';
import { StepperActions } from '../../../modules/vendor/edit-product/components/stepper/actions.stepper.component';
import { ProductVisualizationForm } from '../../../modules/vendor/edit-product/forms/step-3/product-visualization.form';
import { ProductFinalizationForm } from '../../../modules/vendor/edit-product/forms/step-4/product-finalization.form';
import { useDispatch, useSelector } from 'react-redux';
import { setEditProductStepToValidate } from '../../../redux/vendor/products/productsActions';
import { useParams } from 'react-router-dom';
import * as _ from "lodash";
import { VendorState } from '../../../redux/vendor/rootReducer';
import { EditProduct as EditProductModel } from '../../../modules/vendor/edit-product/models/edit-product.model';
import { Product } from '../../../modules/vendor/my-products/models/product.model';
import { setLoading } from '../../../redux/vendor/ui/uiActions';
import { useProductsApi } from '../../../common/hooks/use-products-api.hook';

export const EditProductContext = createContext(null);

export const EditProduct = () => {
    const [t] = useTranslation();
    const steps = [t("PAGES.NEW_PRODUCT.GENERAL_INFO.TITLE"), t("PAGES.NEW_PRODUCT.VARIANTS.VARIANTS"), t("PAGES.NEW_PRODUCT.VISUALIZATION.TITLE"), t("PAGES.NEW_PRODUCT.FINALIZATION.TITLE")];
    const {theme} = useSelector((state: VendorState) => state.settings);
    const [editProduct, setEditProduct] = useState<EditProductModel>()
    const productsAPI = useProductsApi()
    const [activeStep, setEditProductStep] = useState(0)

    const dispatch = useDispatch()
    let { productId } = useParams();
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        dispatch(setEditProductStepToValidate(-1))
    }, [])

    useEffect(() => {
        prepareEditProduct()
    }, [productId])

    const prepareEditProduct = async() => {
        if(productId){
            dispatch(setLoading(true))
            const product: Product = await productsAPI.findOneProduct(productId)
            const editProduct = new EditProductModel()
            await editProduct.mapExistingProductToEditProduct(product, theme.name)
            setEditProduct(editProduct)
            dispatch(setLoading(false))
        } else {
            setEditProduct(new EditProductModel())
        }
        setRefreshKey(prevKey => prevKey + 1);
    }

    return (
        <EditProductContext.Provider value={productId}>
            <PageContainer title={t(productId ? 'PAGES.EDIT_PRODUCT.TITLE':'PAGES.NEW_PRODUCT.TITLE') + `${productId? " : " + editProduct?.name:""}`}>
                <EditProductStepper activeStep={activeStep} steps={steps} />
                <Grid container spacing={2} flex={2} padding={2} key={refreshKey}>
                {editProduct && <Grid item md={activeStep < 3 ? 8:12} xs={12}>
                    {activeStep === 0 && <ProductGeneralInformationForm editProduct={editProduct} setEditProduct={setEditProduct} activeStep={activeStep}/>}
                    {activeStep === 1 && <ProductVariantsAndTypesForm editProduct={editProduct} setEditProduct={setEditProduct} activeStep={activeStep}/>}
                    {activeStep === 2 && <ProductVisualizationForm editProduct={editProduct} setEditProduct={setEditProduct} activeStep={activeStep}/>}
                    {(activeStep === 3 || activeStep === 4) && <ProductFinalizationForm editProduct={editProduct} setEditProduct={setEditProduct} activeStep={activeStep}/>}
                </Grid>}
                {activeStep < 3 && <Grid item md={4}  xs={12}>
                    <ProductPreview editProduct={editProduct} />
                </Grid>}
                </Grid>
                {editProduct && <StepperActions activeStep={activeStep} 
                                                setActiveStep={setEditProductStep} 
                                                nbSteps={steps.length} 
                                                editProduct={editProduct} />}
            </PageContainer>
        </EditProductContext.Provider>
    );
};

