

export const SET_PRIMARY_COLOR = "SET_PRIMARY_COLOR"
export const SET_SECONDARY_COLOR = "SET_SECONDARY_COLOR"
export const SET_TEXT_SECONDARY_COLOR = "SET_TEXT_SECONDARY_COLOR"
export const SET_FONT = "SET_FONT"
export const SET_LOGO = "SET_LOGO"
export const SET_LANGUAGE = "SET_LANGUAGE"
export const SET_INITIAL_THEME = "SET_INITIAL_THEME"
export const SET_VENDOR_NAME = "SET_VENDOR_NAME"
export const RESET_THEME = "RESET_THEME"