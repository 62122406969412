import { useDispatch, useSelector } from "react-redux";
import { VendorState } from "../../../../../redux/vendor/rootReducer";
import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import { Alert, Box, Divider, Grid, Typography } from "@mui/material";
import { ImageDialog } from "../../components/step-3/upload-methods/product-images.component";
import * as _ from "lodash"
import { EditProduct } from "../../models/edit-product.model";
import { ProductVariant } from "../../models/variant.model";

interface ProductFinalizationProps {
    editProduct: EditProduct
    setEditProduct,
    activeStep: number
}

export const ProductsForDefault = (props: ProductFinalizationProps) => {
    const {editProduct, setEditProduct, activeStep} = props
    const {editProductStepToValidate} = useSelector((state: VendorState) => state.products);

    const [t] = useTranslation()
    const [isOpen, setIsOpen] = useState(false)
    const [selectedImage, setSelectedImage] = useState<any>()

    const handleOnProductClick = (productId) => {
        editProduct.variants.forEach(variant => {
            variant.setIsDefault(variant.productId === productId)
        })
        setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)))
    }

    const closeDialog = () => {
        setIsOpen(false)
    }

    return <>{
        editProduct.colors && editProduct.colors.length > 0 && editProduct.colors.map((color, index) => (
            <Fragment key={index}>{_.groupBy(editProduct.variants.filter((variant: ProductVariant) => variant.getIsSelected()), "_color")[color]?.length > 0 && <Box bgcolor={"primary.light"} padding={2} borderRadius={3} position={"relative"} display={"flex"} flexDirection={"column"} marginBottom={1}>
                {color !=="default" && <Typography fontSize={16} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} marginBlock={0.5}>{color}</Typography>}
                {color !=="default" && <Divider />}
                <Grid container spacing={2} marginTop={0.5}>
                {_.groupBy(editProduct.variants.filter((variant: ProductVariant) => variant.getIsSelected()), "_color")[color]?.map((variant: ProductVariant, index) => (
                    <Grid key={index} item xs={12} sm={12} md={6} >
                        <Box display={"flex"} bgcolor={"primary.main"} borderRadius={2} sx={{cursor: "pointer", border: variant.getIsDefault() ? "2px solid black":"", borderColor: "green", position: "relative" }} onClick={() => handleOnProductClick(variant.productId)}>
                            {variant.getIsDefault() && <Typography fontSize={12} color={"white"} letterSpacing={0} position={"absolute"} right={0} top={0} bgcolor={"green"} paddingInline={2} paddingBlock={0.5} sx={{borderTopRightRadius: 2}}>Default</Typography>}
                            
                            <Box display={"flex"} flex={0} paddingTop={1} paddingLeft={1} flexDirection={"column"} >{variant.getProductColors()?.map((hex, index) => (
                                    <Box bgcolor={hex} width={"20px"} height={"20px"} key={index}></Box>
                                ))}</Box>
                            <Box padding={1} flex={0}>
                                {variant.getSeamless64URL() && <img src={`data:image/png;base64,${variant.getSeamless64URL()}`} style={{width: "120px", borderRadius: "5px", cursor: "pointer"}} onClick={(e) => {
                                    e.stopPropagation()
                                    setSelectedImage(variant.getSeamless64URL())
                                    setIsOpen(true)
                                }}/>}
                            </Box>
                            <Box padding={1} >
                                <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} textTransform={'capitalize'} marginRight={3}>{editProduct.brand} </Typography>
                                <Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} marginBlock={0.5}>{variant.getTitle()}</Typography>
                                <Typography fontSize={12} color={"primary.text"} letterSpacing={0} textTransform={'capitalize'} marginBottom={2}>{editProduct.description} </Typography>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td><Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginRight={3}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.SKU")}</Typography></td>
                                        <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} >{variant.getSku()}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginRight={3}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.APPLICATION")} </Typography></td>
                                        <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} textTransform={'capitalize'} >{editProduct.application_types}</Typography></td>
                                    </tr>
                                    {variant.getColor() !== "default" && <tr>
                                        <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginRight={3}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.COLOR")}</Typography></td>
                                        <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} textTransform={'capitalize'} >{variant.getColor()}</Typography></td>
                                    </tr>}
                                    <tr>
                                        <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginRight={3}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.SIZE")} </Typography></td>
                                        <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} >{variant.getSize()?.width}{variant.getSize()?.unit} x {variant.getSize()?.height}{variant.getSize()?.unit}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginRight={3}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.PATTERN")} </Typography></td>
                                        <td><Typography fontSize={12} color={"primary.text"} letterSpacing={0} textTransform={'capitalize'} >{variant.getPattern()}</Typography></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Box>
                        </Box>
                    </Grid>
                ))}
                </Grid>
            </Box>}</Fragment>
        ))
    }
        {editProductStepToValidate === activeStep && !editProduct.variants.find(e => e.getIsDefault()) && <Alert severity="error" sx={{marginTop: 2}}>{t('PAGES.NEW_PRODUCT.GENERAL_INFO.ERRORS.NO_DEFAULT_SELECTED')}</Alert>}
        <ImageDialog base64Image={selectedImage} image={null} isOpen={isOpen} closeDialog={closeDialog} />
    </>
}