import { Box, createTheme, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { SideBar } from "../common/Header/SideBar";
import { MyProducts } from "../pages/vendor/products/my-products.page";
import { EditProduct } from "../pages/vendor/products/edit-product.page";
import { Design } from "../pages/vendor/configurations/design.page";
import { defaultPrimaryColor, defaultSecondaryColor } from "../theme";
import MySpaces from "../pages/vendor/spaces/my-spaces.page";
import { setCompanyName, setFont, setInitialTheme, setLanguage, setLogo, setPrimaryColor, setSecondaryColor, setTextSecondaryColor } from "../redux/vendor/settings/settingsActions";
import { MainHeight, MainHeightMobile, MainWidth, MainWidthMobile } from "../assets/constants.css";
import Dashboard from "../pages/vendor/dashboard/dashboard.page";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {theme as providerTheme} from "../theme"
import Analytics from "../pages/vendor/analytics/analytics.page";
import { VendorState } from "../redux/vendor/rootReducer";
import Config from "../modules/vendor/settings/models/config.model";
import { SeamlessGenerator } from "../pages/vendor/seamlessGenerator/seamless-generator.page";
import { Migrations } from "../pages/vendor/settings/migrations.page";
import { useSettingsApi } from "../common/hooks/use-settings-api.hook";

export function VendorPrivateRoutes() {
    const { isDrawerOpen } = useSelector((state: VendorState) => state.ui);
    const { i18n } = useTranslation();
    const dispatch = useDispatch()
    const isMobileAndDown = useMediaQuery(providerTheme.breakpoints.down('sm'));
    const [mainHeight] = useState(isMobileAndDown ? MainHeightMobile: MainHeight)
    const [mainWidth] = useState(isMobileAndDown ? MainWidthMobile: MainWidth)
    const settingsAPI = useSettingsApi()

    useEffect(() => {
        const getConfig = async () => {
            const companyConfig: Config = await settingsAPI.findConfig()
            i18n.changeLanguage(companyConfig.language[0]);
            dispatch(setPrimaryColor(companyConfig.primaryColor))
            dispatch(setSecondaryColor(companyConfig.secondaryColor))
            dispatch(setTextSecondaryColor(companyConfig.textSecondaryColor))
            dispatch(setLogo(companyConfig.logo))
            dispatch(setFont("Lexend"))
            dispatch(setLanguage(companyConfig.language[0]))
            dispatch(setCompanyName(companyConfig.company))
            dispatch(setInitialTheme())
            document.title = companyConfig.company + ' - Visualizer Backoffice';
        }
        getConfig()
    },[])

    return (
        <div dir={i18n.language === "ar" ? "rtl": "ltr"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box style={{display: 'flex', backgroundColor: "#f7f8f9", height: mainHeight, maxHeight: mainHeight, borderRadius: 15, width: MainWidth}} >
                        <SideBar/>
                        <Box sx={{flexGrow: 1, mainHeight, background: "white", width: MainWidth, borderTopRightRadius: 15, borderBottomRightRadius: 15, overflow: "auto", visibility: isDrawerOpen ? "hidden":"visible"}}>
                            <Routes>
                                <Route path="dashboard" element={<Dashboard />} />

                                <Route path='products'>
                                    <Route path='catalog' element={<MyProducts />} /> 
                                    <Route path='seamless-generator' element={<SeamlessGenerator />} /> 
                                    <Route path='new' element={<EditProduct />} /> 
                                    <Route path=':productId/edit' element={<EditProduct />} /> 
                                </Route>

                                <Route path='spaces/my-selection'>
                                    <Route path='' element={<MySpaces />} /> 
                                </Route>
                                
                                <Route path="engagement/analytics" >
                                    <Route path='' element={<Analytics />} />
                                    <Route path=':analyticType' element={<Analytics />} /> 
                                </Route>

                                <Route path="configuration/design" element={<Design />} />
                                {/* <Route path="/profile" element={<></>} /> */}
                                <Route path="*" element={<></>} />

                                <Route path="migrations" element={<Migrations />} />
                            </Routes>
                        </Box>
                    </Box>
            </LocalizationProvider>
        </div>
    );
}

export let theme = createTheme({
    spacing: (factor) => `${0.5 * factor}rem`,
    palette: {
      primary: {
        main: defaultPrimaryColor,
      },
      secondary: {
        main: defaultSecondaryColor,
      },
    },
    typography: {
      fontFamily: `"Helvetica", "Arial", sans-serif`
      }
  })