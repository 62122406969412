import { ReactNode, createContext, useEffect, useState } from "react";
import { ApiMethod } from "../common/models/api-types.type";
import { User } from "../common/models/user.model";
import { useAuthApi } from "../common/hooks/use-auth-api.hook";
import AuthClientStore from "../common/services/auth-client.service";
import { jwtDecode } from 'jwt-decode';
import { useLocation } from "react-router-dom";
import { config, resetConfig } from "../common/hooks/use-settings-api.hook";

type ContextType = {
  isAuthenticated: boolean;
  isSuperAdmin: boolean;
  user: User
  login(email: string, password: string): Promise<void>;
  logout(): void;
  me(): Promise<User>;
  sendAuthGuardedRequest(
    method: ApiMethod,
    path: string,
    body?: any,
    init?: RequestInit,
  ): Promise<unknown>;
};

export const AuthContext = createContext<ContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [checkIsDone, setCheckIsDone] = useState(false);
  const [user, setUser] = useState<User>();
  const location = useLocation()

  const {
    login: authLogin,
    logout: authLogout,
    me: authMe,
    sendAuthGuardedRequest: authSendAuthGuardedRequest,
  } = useAuthApi();


  const checkAccess = async() => {
    if(AuthClientStore.getRefreshToken()){
      const decodedRefreshToken = jwtDecode<any>(AuthClientStore.getRefreshToken()); 
      if(decodedRefreshToken.exp*1000 < new Date().getTime()){
        logout()
      } else {
        await me()
      }
    } 
    else {
      logout()
    }
    setCheckIsDone(true)
  }

  useEffect(() => {
      checkAccess()
  }, [location])
  
  const login = async (email: string, password: string): Promise<void> => {
    try {
      await authLogin(email, password)
      await me()
    } catch (e) {
      setIsAuthenticated(false);
    }
  };

  const logout = () => {
    authLogout();
    setIsAuthenticated(false);
    setIsSuperAdmin(false);
    setUser(null);
    resetConfig()
  };

  const me = async () => {
    const user = await authMe(() => {
      setIsAuthenticated(false);
    });
    setIsAuthenticated(true);
    setIsSuperAdmin(user.roles[0] === "SUPER_ADMIN")
    setUser(user)
    return user;
  };

  const sendAuthGuardedRequest = async (
    method: ApiMethod,
    path: string,
    // eslint-disable-next-line
    body?: any,
    init?: RequestInit,
  ) => {
    return authSendAuthGuardedRequest(
      () => {
        setIsAuthenticated(false);
      },
      method,
      path,
      body,
      init,
    );
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isSuperAdmin,
        user,
        login,
        logout,
        me,
        sendAuthGuardedRequest,
      }}
    >
      {checkIsDone && children}
    </AuthContext.Provider>
  );
}