import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { useSelector } from 'react-redux';
import { Avatar, useMediaQuery } from '@mui/material';
import { theme as providerTheme } from '../../theme';
import { DarkModeOutlined, Link, NotificationsNoneOutlined, Search } from '@mui/icons-material';
import { TopNavBarHeight } from '../../assets/constants.css';
import { ColorModeContext } from '../../App';
import { VendorState } from '../../redux/vendor/rootReducer';
import { AuthContext } from '../../providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { config, useSettingsApi } from '../hooks/use-settings-api.hook';

export function TopNavBar({title}) {
  const [t] = useTranslation();
  const {theme} = useSelector((state: VendorState) => state.settings);
  const [imgSrc, setImgSrc] = React.useState(theme.logo);
  const isMobileAndDown = useMediaQuery(providerTheme.breakpoints.down('sm'));
  const colorMode = React.useContext(ColorModeContext);
  const { isSuperAdmin } = React.useContext(AuthContext);
  const settingsAPI = useSettingsApi()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleOpenVisualizer = () => {
    const url = `${process.env.REACT_APP_VISUALIZER_URL}/visualizer/${config.alias}`
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  React.useEffect(() => {
    setImgSrc(theme.logo)
  }, [theme])

  return (
    <Box sx={{ flexGrow: 1, paddingTop: 2, paddingBottom: 3, height: TopNavBarHeight, paddingLeft: 2, display: "flex", paddingRight: 5 }} >
      {/* Left Part of the header */}
      <Box flex={2}>
        <Box display={"flex"} flexDirection={"row"}>
          {/* {breadcrumbs?.map((title, index) => {
            if(index < breadcrumbs.length - 1)
              return <>
                        <Typography fontSize={30} letterSpacing={-0.5} fontWeight={"light"} textTransform={'capitalize'}>{title.replace("-"," ")}</Typography>
                        <Typography fontSize={30} letterSpacing={-0.5} fontWeight={"light"} marginInline={1}>{` > `} </Typography>
                    </>
            else return <Typography fontSize={30} letterSpacing={-0.5} fontWeight={"bolder"} textTransform={'capitalize'}>{title.replace("-"," ")}</Typography>
          })} */}
          <Typography fontSize={30} letterSpacing={-0.5} color={"primary.text"} fontWeight={"bolder"} textTransform={'capitalize'}>{title}</Typography>
        </Box>
      </Box>

      {/* Right Part of the header */}
      {!isMobileAndDown && <Box display={"flex"} alignItems={"center"}>
        {!isSuperAdmin && <Box borderRadius={4} border={"2px solid black"} paddingInline={1.5} paddingBlock={0.5} display={"flex"} marginInline={1} sx={{cursor: "pointer"}} onClick={handleOpenVisualizer}>
          <Link />
          <Typography fontSize={14} letterSpacing={-0.5} fontWeight={"bold"} textTransform={'capitalize'} marginLeft={1}>{t('TOPBAR.MY_VISUALIZER')}</Typography>
        </Box>}
        <Box  display={"flex"} alignItems={"center"}>
          <DarkModeOutlined sx={{color: "#c5c5c5", marginLeft: 1, cursor: "pointer"}} onClick={colorMode.toggleColorMode} />
          <Badge badgeContent={""} color="default" sx={{marginLeft: 1}}>
            <NotificationsNoneOutlined sx={{color: "#c5c5c5"}} />
          </Badge>
          <Search sx={{color: "#c5c5c5", marginLeft: 1}} />
          <Avatar src={config?.logo} sx={{border: "4px solid #e4e4e4", height: "40px", width: "40px", marginLeft: 2, objectFit: "contain"}} />
        </Box>
      </Box>}
    </Box>  
  );
}